import { IPFS_UPLOAD_GIF_SIZE_LIMIT, IPFS_UPLOAD_SIZE_LIMIT } from 'lib/ipfs';

import { GIF_MIME_TYPE } from './mimeTypes';

export const isValidIpfsFileSize = (asset: File) => {
  const sizeLimit =
    asset.type === GIF_MIME_TYPE
      ? IPFS_UPLOAD_GIF_SIZE_LIMIT.BYTES
      : IPFS_UPLOAD_SIZE_LIMIT.BYTES;

  return asset.size <= sizeLimit;
};
