import { PlusIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { useQuery } from '@tanstack/react-query';
import { useField } from 'formik';
import { ReactNode } from 'react';
import { ErrorCode, FileError, useDropzone } from 'react-dropzone';

import Media from 'components/Media';
import Spinner from 'components/Spinner';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Field from 'components/base/Field';
import { H2Heading } from 'components/base/Heading';
import Text from 'components/base/Text';

import CloseIcon from 'assets/icons/close-icon.svg';
import useFileUploadS3 from 'hooks/mutations/server/use-file-upload-s3';
import useAssetUrlHeaders from 'hooks/use-asset-url-headers';
import { DEFAULT_VIDEO_PROPS } from 'lib/constants';
import report from 'lib/report';
import { buildImgixUrl, bytesToSize, replaceIpfsPath } from 'utils/assets';
import { getExpectedUploadTime } from 'utils/dates/dates';
import { bytesToMb, isNumber } from 'utils/helpers';
import { getFilePreview } from 'utils/images';
import { pluralizeWord } from 'utils/strings';
import { isValidIpfsFileSize } from 'utils/upload';
import { getAcceptedFileTypes, getFileName } from 'utils/urls';

import { AssetMimeType, S3AssetBucket } from 'types/Assets';
import { MediaAsset } from 'types/media';
import { Maybe, UnsafeAny } from 'types/utils';

type FileUploadLabels = {
  primary: string;
  secondary: string;
};

type FileUploadState = 'initial' | 'uploading' | 'success';

type FileUploadProps = {
  fileCount?: number;
  asset: MediaAsset | undefined;
  isDragActive: boolean;
  isUploading: boolean;
  labels: Record<FileUploadState, FileUploadLabels>;
  onRemove(): void;
};

function FileUploadBase(props: FileUploadProps) {
  const { isDragActive, labels, fileCount = 1, asset, onRemove } = props;

  const state = getUploadState(props);
  const label = labels[state];

  switch (state) {
    case 'initial': {
      return (
        <UploadButton isDragActive={isDragActive} type="button">
          <IconHolder>
            <PlusIcon size={1} />
          </IconHolder>
          <Labels {...label} />
        </UploadButton>
      );
    }

    case 'uploading': {
      return (
        <UploadButton isDragActive={isDragActive} type="button" disabled>
          <IconHolder>
            <Spinner size={16} />
          </IconHolder>
          <Labels {...label} />
        </UploadButton>
      );
    }

    case 'success': {
      return (
        <UploadButton isDragActive={isDragActive} as="div">
          <ButtonPosition>
            <CloseButton
              onClick={(ev) => {
                ev.stopPropagation();
                onRemove();
              }}
            >
              <CloseIcon />
            </CloseButton>
          </ButtonPosition>

          <FileUploadMediaStack asset={asset} fileCount={fileCount} />
          <Labels {...label} />
        </UploadButton>
      );
    }
    default:
      return null;
  }
}

type FileUploadMediaStackProps = {
  fileCount: number;
  asset: MediaAsset | undefined;
};

function FileUploadMediaStack(props: FileUploadMediaStackProps) {
  const { fileCount, asset } = props;

  if (!asset) {
    return <FileUploadMedia as="div" css={{ backgroundColor: '$black5' }} />;
  }

  const getMedia = () => {
    return asset.type === 'image' ? (
      <FileUploadMedia src={asset.src} alt="" />
    ) : (
      <FileUploadMedia {...DEFAULT_VIDEO_PROPS} as="video" src={asset.src} />
    );
  };

  /**
   * using UnsafeAny here because stitches CSS has no type
   */
  const getMediaStackItem = (css: UnsafeAny) => {
    return asset.type === 'image' ? (
      <FileUploadMediaStackItem src={asset.src} alt="" css={css} />
    ) : (
      <FileUploadMediaStackItem
        {...DEFAULT_VIDEO_PROPS}
        as="video"
        src={asset.src}
        css={css}
      />
    );
  };

  if (fileCount === 1) {
    return getMedia();
  }

  return (
    <Box css={{ position: 'relative' }}>
      {getMediaStackItem({ width: '80%', opacity: 0.25, bottom: '-6px' })}
      {getMediaStackItem({ width: '90%', opacity: 0.5, bottom: '-3px' })}
      {getMedia()}
      {fileCount && (
        <CountOuter>
          <CountInner>{fileCount}</CountInner>
        </CountOuter>
      )}
    </Box>
  );
}

type LabelsProps = {
  primary: string;
  secondary: string;
};

function Labels(props: LabelsProps) {
  const { primary, secondary } = props;

  return (
    <LabelsContainer>
      <H2Heading size={1} lineHeight={1} weight="medium" ellipsis>
        {primary}
      </H2Heading>
      <Text size={0} weight="regular" ellipsis color="dim">
        {secondary}
      </Text>
    </LabelsContainer>
  );
}

export type FileUploadBaseProps = {
  name: string;
  accept: ReadonlyArray<AssetMimeType>;
  maxSizeInBytes: number;
  label: ReactNode;
  required: boolean;
};

type FileUploadS3Props = FileUploadBaseProps & {
  bucket: S3AssetBucket;
};

type FileUploadS3BaseProps = FileUploadS3Props & {
  setError: (error: string | undefined) => void;
  setValue: (value: string | null) => void;
  value: string | null;
  error: string | undefined;
};

function FileUploadS3Base(props: FileUploadS3BaseProps) {
  const { setError, setValue, value, error } = props;

  const fileUploadS3 = useFileUploadS3();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept as string[],
    maxSize: props.maxSizeInBytes,
    onDropAccepted: async ([file]) => {
      if (file) {
        setError(undefined);
        await fileUploadS3
          .mutateAsync({
            file: file,
            bucket: props.bucket,
          })
          .then((res) => {
            setValue(res.url);
          })
          .catch(() => {
            report('Failed to upload file to S3');
            setError('Failed to upload file');
          });
      }
    },
    onDropRejected: ([err]) => {
      if (err) {
        const [error] = err.errors;
        if (error) {
          setError(getErrorMessage(error));
        }
      }
    },
  });

  const optimizedAssetUrl = value
    ? buildImgixUrl(value, { w: 180, q: 50 })
    : undefined;

  const assetUrlHeaders = useAssetUrlHeaders({
    // find the asset size of the original quality image
    assetUrl: value ? buildImgixUrl(value, { q: 100 }) : null,
  });

  const fileName = getFileName(value);

  const secondsRemaining = fileUploadS3.meta.secondsRemaining;

  const uploadTimeRemaining =
    secondsRemaining === null
      ? 'Starting upload…'
      : isNumber(secondsRemaining) && secondsRemaining > 0
        ? `Remaining ${getExpectedUploadTime(secondsRemaining)}`
        : 'Finishing upload…';

  const getAsset = (): MediaAsset | undefined => {
    if (!assetUrlHeaders.isSuccess || !optimizedAssetUrl) return undefined;

    const mimeType = assetUrlHeaders.data.mimeType;

    if (mimeType === 'video/mp4' || mimeType === 'video/quicktime') {
      return {
        type: 'video',
        src: optimizedAssetUrl,
        poster: '',
      };
    } else {
      return {
        type: 'image',
        src: optimizedAssetUrl,
        alt: '',
      };
    }
  };

  const acceptedFileTypes = getAcceptedFileTypes(props.accept);

  return (
    <Field
      label={props.label}
      htmlFor={props.name}
      required={props.required}
      error={error}
      touched
    >
      <Container {...getRootProps()} isUploading={fileUploadS3.isPending}>
        <input {...getInputProps()} id={props.name} />
        <FileUpload
          onRemove={() => {
            setValue(null);
          }}
          asset={getAsset()}
          isDragActive={isDragActive}
          isUploading={fileUploadS3.isPending}
          labels={{
            initial: {
              primary: 'Select media',
              secondary: `${acceptedFileTypes} – Max size ${bytesToSize(
                props.maxSizeInBytes
              )}`,
            },
            uploading: {
              primary: 'Uploading…',
              secondary: uploadTimeRemaining,
            },
            success: {
              primary: fileName || 'No file uploaded',
              secondary: assetUrlHeaders.data
                ? bytesToSize(assetUrlHeaders.data.size)
                : '—',
            },
          }}
        />
      </Container>
    </Field>
  );
}

/**
 * @deprecated please use `FileUploadS3Base` instead
 */
function FileUploadS3Formik(props: FileUploadS3Props) {
  const [field, meta, helpers] = useField<string | null>(props.name);

  const setError: FileUploadS3BaseProps['setError'] = (error) => {
    if (error) {
      helpers.setError(error);
    } else {
      helpers.setError(undefined);
    }
  };

  return (
    <FileUploadS3Base
      setError={setError}
      setValue={(value) => {
        if (value) {
          helpers.setValue(value);
        } else {
          helpers.setValue(null);
        }
      }}
      value={field.value ? field.value : null}
      error={meta.error}
      {...props}
    />
  );
}

interface FileUploadIpfsBaseProps extends FileUploadIpfsProps {
  setError: (error: FileError | null) => void;
  clearValue: () => void;
  value: Maybe<string> | undefined;
  error: string | undefined;
}

function FileUploadIpfsBase(props: FileUploadIpfsBaseProps) {
  const { setError, clearValue, error, value } = props;

  const assetUrl = value ? replaceIpfsPath(value) : undefined;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept as string[],
    maxSize: props.maxSizeInBytes,
    onDropAccepted: async ([file]) => {
      if (!file) return;

      if (isValidIpfsFileSize(file)) {
        await props.onDropAccepted(file);
        setError(null);
      } else {
        setError({
          message: 'File too large',
          code: ErrorCode.FileTooLarge,
        });
      }
    },
    onDropRejected: ([err]) => {
      if (err) {
        const [error] = err.errors;
        if (error) {
          setError(error);
        }
      }
    },
  });

  const assetUrlHeaders = useAssetUrlHeaders({
    assetUrl: assetUrl || null,
  });

  const acceptedFileTypes = getAcceptedFileTypes(props.accept);

  const getAsset = (): MediaAsset | undefined => {
    if (!assetUrlHeaders.isSuccess || !assetUrl) return undefined;

    const mimeType = assetUrlHeaders.data.mimeType;

    if (mimeType === 'video/mp4' || mimeType === 'video/quicktime') {
      return {
        type: 'video',
        src: assetUrl,
        /**
         * omitting the poster here as generating one is more
         * hassle than it’s worth for the file upload preview
         */
        poster: '',
      };
    } else {
      return {
        type: 'image',
        src: assetUrl,
        alt: '',
      };
    }
  };

  return (
    <Field
      label={props.label}
      htmlFor={props.name}
      error={error}
      required={props.required}
      /*
       * The error part of the `Field` component only shows when the field has `touched: true` — so in the case of fields like dropzones we need to manually pass `touched: true` through.
       */
      touched
    >
      <Container {...getRootProps()} isUploading={props.isUploading}>
        <input {...getInputProps()} id={props.name} />
        <FileUpload
          onRemove={() => {
            clearValue();
          }}
          asset={getAsset()}
          isDragActive={isDragActive}
          isUploading={props.isUploading || assetUrlHeaders.isLoading}
          labels={{
            initial: {
              primary: 'Select media',
              secondary: `${acceptedFileTypes} – Max size ${bytesToSize(
                props.maxSizeInBytes
              )}`,
            },
            uploading: {
              primary: 'Uploading…',
              // TODO: make this dynamic
              secondary: 'Remaining ~1min',
            },
            success: {
              primary: '1 asset uploaded' || 'No file uploaded',
              secondary: assetUrlHeaders.isSuccess
                ? bytesToSize(assetUrlHeaders.data.size)
                : '—',
            },
          }}
        />
      </Container>
    </Field>
  );
}

type FileUploadIpfsProps = FileUploadBaseProps & {
  onDropAccepted: (file: File) => Promise<void>;
  isUploading: boolean;
};

/**
 * @deprecated please use `FileUploadIpfsBase` instead
 */
function FileUploadIpfsFormik(props: FileUploadIpfsProps) {
  const [field, meta, helpers] = useField<Maybe<string> | undefined>(
    props.name
  );

  const setError: FileUploadIpfsBaseProps['setError'] = (error) => {
    if (error) {
      helpers.setError(getErrorMessage(error));
    } else {
      helpers.setError(undefined);
    }
  };

  const clearValue = () => helpers.setValue(undefined);

  return (
    <FileUploadIpfsBase
      setError={setError}
      clearValue={clearValue}
      error={meta.error}
      value={field.value}
      {...props}
    />
  );
}

type MultiFileUploadIpfsProps = FileUploadBaseProps & {
  isUploading: boolean;
  required: boolean;
};

/**
 * @deprecated look into using `FileUpload` instead
 */
function MultiFileUploadIpfs(props: MultiFileUploadIpfsProps) {
  const [field, meta, helpers] = useField<Array<File>>(props.name);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept as string[],
    maxSize: props.maxSizeInBytes,
    onDropAccepted: (acceptedFiles) => {
      helpers.setValue(acceptedFiles);
    },
    onDropRejected: ([err]) => {
      if (err) {
        const [error] = err.errors;
        if (error) {
          helpers.setError(getErrorMessage(error));
        }
      }
    },
  });

  const fieldValue = field.value[0];

  const imagePreview = useQuery({
    queryKey: ['ImagePreview', fieldValue?.name],
    queryFn: () => {
      return fieldValue ? getFilePreview(fieldValue) : undefined;
    },
    enabled: Boolean(fieldValue),
  });

  const fileCount = field.value.length;
  const fileSize = field.value.reduce((acc, curr) => curr.size + acc, 0);

  const fileCountLabel = `${fileCount} ${pluralizeWord(
    'asset',
    fileCount
  )} added`;

  const acceptedFileTypes = getAcceptedFileTypes(props.accept);

  return (
    <Field
      label={props.label}
      htmlFor={props.name}
      error={meta.error}
      required={props.required}
      /*
       * The error part of the `Field` component only shows when the field has `touched: true` — so in the case of fields like dropzones we need to manually pass `touched: true` through.
       */
      touched
    >
      <Container {...getRootProps()} isUploading={props.isUploading}>
        <input {...getInputProps()} id={props.name} />
        <FileUpload
          fileCount={fileCount}
          onRemove={() => {
            helpers.setValue([]);
          }}
          asset={
            imagePreview.data
              ? { alt: '', src: imagePreview.data, type: 'image' }
              : undefined
          }
          isDragActive={isDragActive}
          isUploading={props.isUploading}
          labels={{
            initial: {
              primary: 'Select media',
              secondary: `${acceptedFileTypes} – Max size ${bytesToSize(
                props.maxSizeInBytes
              )}`,
            },
            uploading: {
              primary: 'Uploading…',
              // TODO: make this dynamic
              secondary: 'Remaining ~1min',
            },
            success: {
              primary: fileCountLabel,
              secondary: fileSize
                ? `${Math.ceil(bytesToMb(fileSize))}MB total`
                : '0MB total',
            },
          }}
        />
      </Container>
    </Field>
  );
}

const UploadButton = styled(Button, {
  width: '100%',
  borderRadius: '$2',
  position: 'relative',

  gap: '$4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  variants: {
    disabled: {
      true: {},
    },
    size: {
      1: {},
    },
    isDragActive: {
      true: {
        transform: 'scale3d(1.02, 1.02, 1.02)',
        borderColor: '$black100',
        boxShadow: '$regular1, inset 0px 0px 0px 1px $colors$black100',
      },
    },
  },
  compoundVariants: [
    {
      size: 1,
      css: {
        height: 'auto',
        padding: '$2',
      },
    },
  ],
});

const Container = styled(Box, {
  '&:focus': {
    outline: 'none',
  },
  [`&:focus-visible > ${Button}`]: {
    borderColor: '$black100',
    outline: '4px solid $black30',
  },
  variants: {
    isUploading: {
      true: {
        pointerEvents: 'none',
      },
    },
  },
});

const IMAGE_SIZE = 56;

const ButtonPosition = styled('div', {
  position: 'absolute',
  top: '50%',
  right: '$2',
  transform: 'translateY(-50%)',
});

const CloseButton = styled(Button, {
  right: '$2',
  defaultVariants: {
    icon: 'standalone',
    size: 0,
    type: 'button',
    variant: 'ghost',
  },
});

const LabelsContainer = styled('div', {
  gap: '$1',
  display: 'flex',
  textAlign: 'left',
  flexDirection: 'column',
  overflow: 'hidden',
});

const IconHolder = styled('div', {
  width: IMAGE_SIZE,
  height: IMAGE_SIZE,
  borderRadius: '$1',
  backgroundColor: '$black5',
  flexShrink: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const FileUploadMedia = styled(Media, {
  width: IMAGE_SIZE,
  height: IMAGE_SIZE,
  borderRadius: '$2',
  position: 'relative',
});

const FileUploadMediaStackItem = styled(FileUploadMedia, {
  left: '50%',
  position: 'absolute',
  transform: 'translateX(-50%)',
});

const CountOuter = styled('div', {
  position: 'absolute',
  background: '$white100',
  top: '-$3',
  right: '-$3',
  padding: '$1',
  borderRadius: '$round',
});

const CountInner = styled('div', {
  paddingX: '$2',
  paddingY: '$1',
  background: '$black100',
  color: '$white100',
  fontSize: '$0',
  borderRadius: '$round',
});

function getUploadState(args: FileUploadProps): FileUploadState {
  if (args.isUploading) {
    return 'uploading';
  } else if (args.asset) {
    return 'success';
  } else {
    return 'initial';
  }
}

export function getErrorMessage(error: FileError) {
  if (error.code === ErrorCode.FileInvalidType) {
    return 'Invalid file type';
  } else if (error.code === ErrorCode.FileTooLarge) {
    return 'File is too large';
  } else if (error.code === ErrorCode.FileTooSmall) {
    return 'File is too small';
  } else if (error.code === ErrorCode.TooManyFiles) {
    return 'Too many files';
  }
}

const FileUpload = Object.assign(FileUploadBase, {
  S3: FileUploadS3Base,
  S3Formik: FileUploadS3Formik,
  IpfsFormik: FileUploadIpfsFormik,
  Ipfs: FileUploadIpfsBase,
  IpfsMultiFormik: MultiFileUploadIpfs,
});

export default FileUpload;
