import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';

import { secondsToMs } from 'utils/numbers';
import { getFileExtension } from 'utils/urls';

import { S3AssetBucket } from 'types/Assets';

type SignFile = {
  url: string;
};

interface SignFileVariables {
  file: File;
  bucket: S3AssetBucket;
}

export default function useSignFile<TError = Error, TContext = unknown>(
  options?: UseMutationOptions<SignFile, TError, SignFileVariables, TContext>
) {
  return useMutation({
    ...options,
    mutationFn: async (variables) => {
      const query = await axios.post<SignFile>('/api/sign-url', {
        path: getUploadPath(variables),
        bucket: variables.bucket,
        timeoutErrorMessage: 'Upload timed out',
        timeout: secondsToMs(300), // 5 minutes
      });
      return {
        url: query.data.url,
      };
    },
  });
}

interface UploadPathOptions {
  file: File;
  bucket: S3AssetBucket;
}

const getRandString = () => Math.random().toString(36).substr(2, 9);

function getUploadPath(args: UploadPathOptions): string {
  const fileName = `${getRandString()}.${getFileExtension(args.file.name)}`;

  switch (args.bucket) {
    case 'collections':
      return `collections/${fileName}`;
    case 'models':
      return `creators/collection/${fileName}`;
    case 'profiles':
      return `creators/profile/${fileName}`;
    case 'worlds':
      return `worlds/${fileName}`;
  }
}
