import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosRequestConfig, AxiosProgressEvent } from 'axios';
import { useState } from 'react';
import { useGetSet } from 'react-use';

import useSignFile from 'hooks/mutations/use-sign-file';
import { UseMutationResultWithMeta } from 'queries/types';
import { isNumberType } from 'utils/helpers';
import { clampPercent } from 'utils/numbers';

import { S3AssetBucket } from 'types/Assets';
import { Dimensions } from 'types/media';

type LoadedImage = Dimensions & { src: string };

export type FileUploadS3 = {
  url: string;
  previewBase64: LoadedImage | null;
};

type FileUploadS3Variables = {
  file: File;
  bucket: S3AssetBucket;
};

export default function useFileUploadS3<TError = Error, TContext = unknown>(
  options: UseMutationOptions<
    FileUploadS3,
    TError,
    FileUploadS3Variables,
    TContext
  > = {}
): UseMutationResultWithMeta<
  UseMutationResult<FileUploadS3, TError, FileUploadS3Variables, TContext>,
  UploadProgress
> {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [percentProgress, setPercentProgress] = useState(0);
  const [secondsRemaining, setSecondsRemaining] =
    useState<UploadProgress['secondsRemaining']>(null);
  const [previewBase64, setPreviewBase64] = useState<LoadedImage | null>(null);

  // use an explicit getter function here (as onUploadProgress forms a closure)
  const [getStartedAt, setStartedAt] = useGetSet(Date.now());

  const signFile = useSignFile();

  const handleFileUpload = useMutation({
    ...options,
    mutationFn: async (variables) => {
      const { file, bucket } = variables;

      const loadedImageDetails: LoadedImage | null = await new Promise(
        (resolve) => {
          if (file.type.startsWith('image')) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              const base64 = reader.result;
              if (typeof base64 !== 'string') {
                return resolve(null);
              }

              // Create a new image and set its source to the base64 string
              const image = new Image();
              image.src = base64;

              // When the image is loaded, its dimensions are available
              image.onload = () => {
                setPreviewBase64({
                  width: image.width,
                  height: image.height,
                  src: base64,
                });
                resolve({
                  width: image.width,
                  height: image.height,
                  src: base64,
                });
              };
            };
          } else {
            resolve(null);
          }
        }
      );

      setStartedAt(Date.now());

      const uploadPath = await signFile.mutateAsync({ file, bucket });

      const uploadOptions: AxiosRequestConfig = {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (event: AxiosProgressEvent) => {
          if (!isNumberType(event.total)) return;

          setLastUpdatedAt(Date.now());
          setPercentProgress(clampPercent((event.loaded / event.total) * 100));
          const startedAt = getStartedAt();
          const secondsDiff = (Date.now() - startedAt) / 1000;
          const uploadSpeed = event.loaded / secondsDiff;
          const timeRemaining = (event.total - event.loaded) / uploadSpeed;
          setSecondsRemaining(Math.floor(timeRemaining));
        },
      };

      await axios.put(uploadPath.url, file, uploadOptions);

      return {
        url: getPathFromUrl(uploadPath.url),
        previewBase64: loadedImageDetails,
      };
    },
  });

  return {
    ...handleFileUpload,
    meta: {
      lastUpdatedAt,
      secondsRemaining,
      percentProgress,
      previewBase64,
    },
  };
}

function getPathFromUrl(url: string) {
  return url.split('?')[0] as string;
}

type UploadProgress = {
  lastUpdatedAt: number;
  percentProgress: number;
  secondsRemaining: number | null;
  previewBase64: LoadedImage | null;
};
