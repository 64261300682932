import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { UPLOAD_MIME_TYPES } from 'utils/mimeTypes';

type AssetUrlHeadersVariables = {
  assetUrl: string | null;
};

const urlSchema = z.string().url();

export default function useAssetUrlHeaders(
  variables: AssetUrlHeadersVariables
) {
  return useQuery({
    enabled: urlSchema.safeParse(variables.assetUrl).success,
    queryKey: ['asset', 'url', 'headers', variables.assetUrl],
    queryFn: async () => {
      if (!variables.assetUrl) {
        throw new Error('assetUrl is required');
      }

      const response = await fetch(variables.assetUrl, {
        method: 'HEAD',
      });

      const contentLength = response.headers.get('content-length');
      const contentType = response.headers.get('content-type');

      return {
        size: z.coerce.number().parse(contentLength),
        mimeType: z.enum(UPLOAD_MIME_TYPES).parse(contentType),
      };
    },
  });
}
