import { mbToBytes } from 'utils/helpers';

const toAssetSizeMap = (options: { sizeInMb: number }) => {
  const { sizeInMb } = options;

  return {
    BYTES: mbToBytes(sizeInMb),
    MB: sizeInMb,
  } as const;
};

export const IPFS_UPLOAD_SIZE_LIMIT = toAssetSizeMap({ sizeInMb: 300 });
export const IPFS_UPLOAD_GIF_SIZE_LIMIT = toAssetSizeMap({ sizeInMb: 75 });
